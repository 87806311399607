import Logo from "./images/sqlgpt_logo.png";
import "./App.css";

function App() {
  return (
    <div className="app">
      <img className="logo" src={Logo} alt="SQLGPT" />
    </div>
  );
}

export default App;
